
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import stepFielfdMixin from "../stepFielfdMixin";
import rules from "@/components/account/rules";
import { mapActions, mapGetters, mapState } from "vuex";
import City from "@/models/City";

export default Vue.extend({
  name: "post-surgery-patient-pick-information",
  mixins: [stepFielfdMixin],
  data() {
    return {
      postDateMenu: false,
      postProcedureDateMenu: false,
      rules,
      stateCities: [],
      loadingCities: false,
      showPatientWill: false,
      fields: {
        postDate: "",
        postSurgeyProcedure: "",
        postSurgeon: "",
        postProcedureDate: "",
        // releaseTime: "",
        caregiverFamilyName: "",
        caregiverRelationship: "",
        recoveryPhone: "", // 17868027461
        recoveryFacilityName: "",
        caregiverPhone: "", // 17868027461
        stayDays: 0,
        driverName: "",
        recoveryStreet: "",
        recoveryUnitAptNo: "",
        recoveryState: "",
        recoveryZip: "", // 12345
        recoveryCity: "",
      },
    };
  },
  computed: {
    ...mapGetters(["getpatientN"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
  },
  watch: {
    postDateMenu(val: boolean) {
      try {
        val &&
          setTimeout(
            () => (this.$refs.postDateDatepicker.activePicker = "YEAR")
          );
      } catch (error) {
        /*  */
      }
    },
    postProcedureDateMenu(val: boolean) {
      try {
        val &&
          setTimeout(
            () => (this.$refs.postProcedureDateDatepicker.activePicker = "YEAR")
          );
      } catch (error) {
        /*  */
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    savePostDate(date: string): void {
      this.$refs.postDateMenu.save(date);
    },
    postProcedureDateDate(date: string): void {
      this.$refs.postProcedureDateMenu.save(date);
    },
  },
});
